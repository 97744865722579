import { Container, Box, Typography, useMediaQuery, Grid, SvgIcon } from "@mui/material"
import DesktopImage from "static/bg_main_short.png"
import MobileImage from "static/bg_main_mobile_short.png"

import { ReactComponent as CheckedPie } from "static/icons/checked_pie.svg"
import LoginForm from "../../shared/LoginForm"
const CheckedPieIcon = (props) => <SvgIcon component={CheckedPie} inheritViewBox {...props} />

const processList = [
  "Fast process — 1 business day from application to disbursement of funds",
  "Loan from PHP 100 000 up to  PHP 2 000 000 for 12-24 months",
  "High approval rate",
  "Your car stays with you",
  "Without guarantors",
]

const ProcessListItem = (props) => {
  const { icon, title } = props

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: { xs: 1, md: 4 }, mr: { xs: 0, md: 3 } }}>
      {icon}
      <Typography variant={matches ? "h5" : "body1"} sx={{ fontWeight: 700, ml: { xs: 1.5, md: 1.5 }, mt: 0.5 }}>
        {title}
      </Typography>
    </Box>
  )
}

const DesktopEntry = () => {
  return (
    <Box
      id="entry"
      elevation={0}
      sx={{
        marginTop: "-90px",
        height: 887,
        display: "flex",
        justifyContent: "space-between",
        backgroundImage: `url(${DesktopImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "text.light",
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ width: "888px", mt: 20.5, mb: 6 }}>
          <Typography variant="h1">Car Refinancing Sangla OR/CR</Typography>

          <Typography variant="h3" sx={{ mt: 4, lineHeight: "33px", fontFamily: "inter" }}>
            Keep using your car while your OR/CR gets you our lowest interest loan
          </Typography>
        </Box>

        <Grid container sx={{ maxWidth: 725 }}>
          {processList.map((txt, i) => (
            <Grid item key={i} xs={6}>
              <ProcessListItem
                icon={<CheckedPieIcon sx={{ width: 48, height: 48, color: "transparent" }} />}
                title={txt}
              />
            </Grid>
          ))}
        </Grid>

        <LoginForm />
      </Container>
    </Box>
  )
}

const MobileEntry = () => {
  return (
    <Box
      id="entry"
      elevation={0}
      sx={{
        marginTop: "-90px",
        px: 2,
        pt: 14,
        pb: 3.5,
        backgroundImage: `url(${MobileImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "text.light",
        minHeight: 950,
      }}
    >
      <Box sx={{ mt: 4, mb: 3 }}>
        <Typography variant="h1">Car Refinancing Sangla OR/CR</Typography>

        <Typography variant="body1" sx={{ mt: 2, fontFamily: "inter" }}>
          Keep using your car while your OR/CR gets you our lowest interest loan
        </Typography>
      </Box>

      <Grid container sx={{ mb: 3 }}>
        {processList.map((txt, i) => (
          <Grid item key={i} xs={12}>
            <ProcessListItem
              icon={<CheckedPieIcon sx={{ width: 48, height: 48, color: "transparent" }} />}
              title={txt}
            />
          </Grid>
        ))}
      </Grid>
      <LoginForm />
    </Box>
  )
}

const Entry = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  const child = matches ? <DesktopEntry /> : <MobileEntry />

  return <Box>{child}</Box>
}

export default Entry
