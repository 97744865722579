import { useEffect } from "react"
import { Box } from "@mui/material"

import useAppRequest from "hooks/use_app_request"

import StepBlock from "components/shared/StepBlock"
import LivingAddress from "components/shared/fields/LivingAddress"
import LivingCity from "components/shared/fields/LivingCity"
import ResidenceOwnership from "components/shared/fields/ResidenceOwnership"
import LengthOfStay from "components/shared/fields/LengthOfStay"
import LivingBill from "components/shared/fields/LivingBill"
import HouseSketch from "components/shared/fields/HouseSketch"

import SecondaryButton from "components/shared/buttons/Secondary"

import useAppStore from "store"

const Residence = () => {
  const stepName = "residence"

  const { handleSubmit } = useAppRequest(stepName, {
    living_city_id: "living_city_id",
    living_address: "living_street_house_apt",
    residence_ownership_id: "residence_ownership_id",
    length_of_stay: "length_of_stay",
    utility_bill: "utility_bill",
    house_sketch: "house_sketch",
  })

  const updateAppGeolocation = useAppStore((state) => state.updateAppGeolocation)

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords

          updateAppGeolocation(longitude, latitude).catch((e) => console.log(e.message))
        },
        (error) => console.error("Error getting user location:", error),
      )
    } else {
      console.error("Geolocation is not supported by this browser.")
    }
  }, [])

  return (
    <StepBlock stepName={stepName} stepTitle="Address">
      <Box component="form" onSubmit={handleSubmit}>
        <Box sx={{ maxWidth: "388px" }}>
          <LivingCity />
          <LivingAddress />
          <ResidenceOwnership />
          <LengthOfStay />
          <LivingBill />
          <HouseSketch />

          <SecondaryButton text="Next" type="submit" />
        </Box>
      </Box>
    </StepBlock>
  )
}

export default Residence
