import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"

const CarPrice = () => {
  const id = "car_price"

  const validate = (value) => {
    if (value === "") return "This field is required"

    if (value > 999999999) return "Price is to large"
    if (value < 10000) return "Price is to short"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  return (
    <MyTextField
      id={id}
      _label="Unit price"
      placeholder="Enter price"
      type="number"
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    />
  )
}

export default CarPrice
