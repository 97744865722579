import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"

const CompanyPosition = () => {
  const id = "company_position"

  const validate = (value) => {
    if (value === "") return "This field is required"

    if (value.length < 5) return "Is too short (minimum is 5 characters)"
    if (value.length > 50) return "Is too long (maximum is 50 characters)"

    return ""
  }

  const { handleChange, handleBlur, inputValue, errorMessage } = useInput(id, validate)

  return (
    <MyTextField
      id={id}
      _label="Position"
      placeholder=""
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    />
  )
}

export default CompanyPosition
