import { Box, Stack } from "@mui/material"

import useAppStore from "store"
import useAppRequest from "hooks/use_app_request"

import FullName from "components/shared/fields/FullName"
import Email from "components/shared/fields/Email"
import SubmitButton from "components/shared/buttons/Submit"
import PrivacyPolicy from "components/shared/Calculator/PrivacyPolicy"
import CarYear from "components/shared/fields/CarYear"
import CarModel from "components/shared/fields/CarModel"
import CarIsCuf from "components/shared/fields/CarIsCuf"
import TransportType from "components/shared/fields/TransportType"

const GeneralInfoShort = () => {
  const stepName = "general_info_short"

  const transportType = useAppStore((state) => state.fields.car_transport_type_id)

  const { handleSubmit } = useAppRequest(stepName, {
    full_name: "full_name",
    email: "email",
    car_transport_type_id: "car_info.transport_type_id",
    car_model: "car_info.name",
    car_year: "car_info.year",
    car_is_cuf: "car_info.is_cuf",
  })

  const stepSubmit = (event) => {
    handleSubmit(event)
  }

  return (
    <Box component="form" onSubmit={stepSubmit}>
      <FullName />
      <Email />
      <TransportType />

      {transportType === 1 && (
        <>
          <CarModel />

          <Stack direction="row" spacing={3} sx={{ mb: { xs: 2, md: 0 } }}>
            <CarYear />
            <CarIsCuf />
          </Stack>
        </>
      )}

      <Stack spacing={3} direction="row" sx={{ maxHeight: "52px", alignContent: "center" }}>
        <SubmitButton text="Send" type="submit" />
        <PrivacyPolicy />
      </Stack>
    </Box>
  )
}

export default GeneralInfoShort
