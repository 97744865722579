import { Box } from "@mui/material"

import Gallery from "components/shared/Gallery"
import useAppRequest from "hooks/use_app_request"
import StepBlock from "components/shared/StepBlock"
import SecondaryButton from "components/shared/buttons/Secondary"

const CarPhotos = () => {
  const stepName = "car_photos"

  const { handleSubmit } = useAppRequest(stepName, {
    car_photos: "car_photos",
  })

  return (
    <StepBlock stepName={stepName} stepTitle="Car photos">
      <Box component="form" onSubmit={handleSubmit}>
        <Gallery />
        <SecondaryButton text="Next" type="submit" />
      </Box>
    </StepBlock>
  )
}

export default CarPhotos
