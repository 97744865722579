import { useEffect, useState } from "react"

import InputMask from "react-input-mask"
import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"
import useAppStore from "store"

const DocumentNumber = () => {
  const id = "document_number"
  const [initialLoad, setInitialLoad] = useState(true)

  const documentType = useAppStore((state) => state.fields.document_type)

  const validate = (value) => {
    if (value === "") return "This field is required"

    if (value.includes("_")) return "Please enter valid ID number"

    return ""
  }

  const props = useInput(id, validate)

  useEffect(() => {
    const { updateField } = props

    if (!initialLoad) updateField(id, "")

    setInitialLoad(false)
  }, [documentType])

  return (
    <>
      {documentType === 18 && <GsisID id={id} {...props} />}
      {documentType === 6 && <PrcID id={id} {...props} />}
      {documentType === 3 && <Passport id={id} {...props} />}
      {documentType === 4 && <PhilHealthID id={id} {...props} />}
      {documentType === 20 && <PhilSysID id={id} {...props} />}
      {documentType === 21 && <PostalID id={id} {...props} />}
      {documentType === 1 && <SssID id={id} {...props} />}
      {documentType === 2 && <TinID id={id} {...props} />}
      {documentType === 16 && <UmidCard id={id} {...props} />}
      {documentType === 7 && <VotersID id={id} {...props} />}
    </>
  )
}

// Driver's license,5
// GSIS ID,18
// PRC ID,6
// Passport,3
// PhilHealth ID,4
// PhilSys ID,20
// Postal ID,21 //
// SSS ID,1
// TIN ID,2
// UMID card,16
// Voter’s ID,7

// const DriverLicense = (props) => {
//   const { id, handleChange, inputValue, errorMessage, handleBlur } = props
//
//   const mask = "a99-99-999999"
//   const placeholder = "XXX-XX-XXXXXX"
//   const hint =
//     "Driver's license number consists of 11 symbols starting with a letter. For example, N12-34-567890"
//
//   return (
//     <InputMask
//       mask={mask}
//       maskPlaceholder="_"
//       alwaysShowMask
//       value={inputValue}
//       onChange={handleChange}
//       onBlur={handleBlur}
//       error={errorMessage.length > 0}
//       helperText={errorMessage || hint}
//     >
//       <MyTextField id={id} _label="Document number" placeholder={placeholder} />
//     </InputMask>
//   )
// }

const GsisID = (props) => {
  const { id, handleChange, inputValue, errorMessage, handleBlur } = props

  const mask = "9999-9999-9999-9999"
  const placeholder = "XXXX-XXXX-XXXX-XXXX"
  const hint = "Driver's For example, N12-34-567890"

  return (
    <InputMask
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errorMessage.length > 0}
      helperText={errorMessage || hint}
    >
      <MyTextField id={id} _label="Document number" placeholder={placeholder} />
    </InputMask>
  )
}

const PrcID = (props) => {
  const { id, handleChange, inputValue, errorMessage, handleBlur } = props

  const mask = "9999999"
  const placeholder = "XXXXXXX"
  const hint = "PRC ID number consists of 7 digits. For example, 1234567"

  return (
    <InputMask
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errorMessage.length > 0}
      helperText={errorMessage || hint}
    >
      <MyTextField id={id} _label="Document number" placeholder={placeholder} />
    </InputMask>
  )
}

const Passport = (props) => {
  const { id, handleChange, inputValue, errorMessage, handleBlur } = props

  const mask = "a*999999*"
  const placeholder = "XXXXXXXXX"
  const hint = "Passport number consists of 9 symbols starting with a letter. For example, P1234567A or EC1234567"

  return (
    <InputMask
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errorMessage.length > 0}
      helperText={errorMessage || hint}
    >
      <MyTextField id={id} _label="Document number" placeholder={placeholder} />
    </InputMask>
  )
}

const PhilHealthID = (props) => {
  const { id, handleChange, inputValue, errorMessage, handleBlur } = props

  const mask = "99-999999999-9"
  const placeholder = "XX-XXXXXXXXX-X"
  const hint = "PhilHealth ID number consists of 12 digits. For example, 12-123456789-1"

  return (
    <InputMask
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errorMessage.length > 0}
      helperText={errorMessage || hint}
    >
      <MyTextField id={id} _label="Document number" placeholder={placeholder} />
    </InputMask>
  )
}

const PhilSysID = (props) => {
  const { id, handleChange, inputValue, errorMessage, handleBlur } = props

  const mask = "PSN-9999-9999999-9"
  const placeholder = "PSN-XXXX-XXXXXXX-X"
  const hint = "PhilSys ID number consists of 12 digits. For example, PSN-1234-1234567-1"

  return (
    <InputMask
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errorMessage.length > 0}
      helperText={errorMessage || hint}
    >
      <MyTextField id={id} _label="Document number" placeholder={placeholder} />
    </InputMask>
  )
}

const PostalID = (props) => {
  const { id, handleChange, inputValue, errorMessage, handleBlur } = props

  const mask = "PRN **9999999999 a"
  const placeholder = "PRN XXXXXXXXXXXX X"
  const hint = "Postal ID number consists of 13 symbols (not including PRN). For example, PRN 100141234567 P"

  return (
    <InputMask
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errorMessage.length > 0}
      helperText={errorMessage || hint}
    >
      <MyTextField id={id} _label="Document number" placeholder={placeholder} />
    </InputMask>
  )
}

const SssID = (props) => {
  const { id, handleChange, inputValue, errorMessage, handleBlur } = props

  const mask = "99-9999999-9"
  const placeholder = "XX-XXXXXXX-X"
  const hint = "SSS ID number consists of 10 digits. For example, 12-3456789-0"

  return (
    <InputMask
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errorMessage.length > 0}
      helperText={errorMessage || hint}
    >
      <MyTextField id={id} _label="Document number" placeholder={placeholder} />
    </InputMask>
  )
}

const TinID = (props) => {
  const { id, handleChange, inputValue, errorMessage, handleBlur } = props

  const [mask, setMask] = useState(inputValue?.match(/\d/g)?.length > 9 ? "999-999-999-999" : "999-999-999")

  // const mask = "999-999-999[-999]"
  const placeholder = "XXX-XXX-XXX-XXX"
  const hint = "TIN ID number consists of 9 or 12 digits. For example, 123-123-123 or 123-123-123-000"

  return (
    <InputMask
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errorMessage.length > 0}
      helperText={errorMessage || hint}
      onKeyDown={(e) => {
        if (inputValue?.match(/\d/g)?.length >= 9 && e.key != "Backspace") setMask("999-999-999-999")
      }}
      onKeyUp={() => {
        if (inputValue?.match(/\d/g)?.length < 10) setMask("999-999-999")
      }}
    >
      <MyTextField id={id} _label="Document number" placeholder={placeholder} />
    </InputMask>
  )
}

const UmidCard = (props) => {
  const { id, handleChange, inputValue, errorMessage, handleBlur } = props

  const mask = "CRN-9999-9999999-9"
  const placeholder = "CRN-XXXX-XXXXXXX-X"
  const hint = "UMID card number consists of 12 digits. For example, CRN-1234-1234567-1"

  return (
    <InputMask
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errorMessage.length > 0}
      helperText={errorMessage || hint}
    >
      <MyTextField id={id} _label="Document number" placeholder={placeholder} />
    </InputMask>
  )
}

const VotersID = (props) => {
  const { id, handleChange, inputValue, errorMessage, handleBlur } = props

  const [mask, setMask] = useState(
    inputValue?.match(/\d/g)?.length > 22 ? "VIN: ****-*****-*************-*" : "VIN: ****-*****-*************",
  )

  // const mask = "VIN: XXXX-XXXXX-XXXXXXXXXXXXX-X"
  const placeholder = "VIN: XXXX-XXXXX-XXXXXXXXXXXXX-X"
  const hint = "Voter's ID number consists of 22 or 23 symbols. For example, VIN: 1234-1234A-A1234ABC12345-1"

  return (
    <InputMask
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errorMessage.length > 0}
      helperText={errorMessage || hint}
      onKeyDown={(e) => {
        if (inputValue?.match(/\d/g)?.length >= 22 && e.key != "Backspace") setMask("VIN: ****-*****-*************-*")
      }}
      onKeyUp={() => {
        if (inputValue?.match(/\d/g)?.length < 23) setMask("VIN: ****-*****-*************")
      }}
    >
      <MyTextField id={id} _label="Document number" placeholder={placeholder} />
    </InputMask>
  )
}

export default DocumentNumber
