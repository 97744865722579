import BaseSelect from "components/base/Select"

import useSelect from "hooks/use_select"
import useAppStore from "store"

const Term = () => {
  const id = "term"
  const { application_type_id } = useAppStore((state) => state.fields)

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleOpen, handleClose, open } = useSelect(id, validate)

  const sanglaTerms = Array.from(new Array(13), (x, i) => ({
    id: 12 + i,
    name: 12 + i,
  }))

  const partnerTerms = [12, 18, 24, 30, 36, 48].map((num) => ({
    id: num,
    name: num,
  }))

  const terms = ["auto_financing", "moto_financing"].includes(application_type_id) ? partnerTerms : sanglaTerms

  return (
    <BaseSelect
      id={id}
      label="Preferrable loan term"
      placeholder="Select"
      error={errorMessage?.length > 0}
      value={inputValue}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
      items={terms}
      helperText={errorMessage || "Select the loan term you would like to apply for."}
    />
  )
}

export default Term
