import { FormControl, FormLabel, FormControlLabel, RadioGroup, FormHelperText, Typography } from "@mui/material"
import BaseRadio from "components/base/Radio"
import useInput from "hooks/use_input"
import { useEffect, useState } from "react"
import useAppStore from "../../../store"

const Gender = () => {
  const id = "gender"
  const [genders, setGenders] = useState([])
  const fetchGenders = useAppStore((state) => state.getGenders)

  useEffect(() => {
    fetchGenders()
      .then((response) => setGenders(response.data))
      .catch((e) => console.log(e))
  }, [])

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const { handleChange, handleBlur, inputValue, errorMessage } = useInput(id, validate)

  return (
    <FormControl sx={{ minWidth: 185 }} error={errorMessage?.length > 0}>
      <FormLabel>
        <Typography variant="body2" color="text.middle" sx={{ mb: 1 }}>
          Gender
        </Typography>
      </FormLabel>
      <RadioGroup id={id} row value={inputValue} onChange={handleChange} onBlur={handleBlur}>
        {genders.map((gender) => (
          <FormControlLabel key={gender.id} value={gender.id} label={gender.name} control={<BaseRadio />} />
        ))}
      </RadioGroup>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

export default Gender
