import { useEffect } from "react"

import { Modal, Box, Typography, Link } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import useClientStore from "store/client"

import ClientPhone from "components/shared/fields/ClientPhone"
import PinCode from "components/shared/fields/PinCode"
import SubmitButton from "components/shared/buttons/Submit"

import useNavigateSearch from "hooks/use_navigate_search"
import useModal from "hooks/use_modal"
import useAppStore from "store"

const LoginForm = () => {
  const { open, handleOpen, handleClose } = useModal()

  const seconds = useClientStore((state) => state.pin_code_waiting_seconds)
  const mobilePhone = useClientStore((state) => state.mobile_phone)
  const errorMessages = useClientStore((state) => state.error_messages)
  const updateErrorMessage = useClientStore((state) => state.updateErrorMessage)
  const updateClientKey = useClientStore((state) => state.updateKey)
  const sendClientPin = useClientStore((state) => state.sendClientPin)
  const login = useClientStore((state) => state.login)
  const clearLogin = useClientStore((state) => state.clearLogin)

  const updateField = useAppStore((state) => state.updateField)
  const updateAppKey = useAppStore((state) => state.updateKey)

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        updateClientKey("pin_code_waiting_seconds", seconds - 1)
      } else {
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  const navigate = useNavigateSearch()

  const handleSubmit = (event) => {
    event.preventDefault()

    if (errorMessages["mobile_phone"]?.length > 0) return

    checkPin()
  }

  const sendOtp = () => {
    if (errorMessages["mobile_phone"]?.length > 0 || seconds > 0) return

    updateAppKey("backdrop", true)
    updateClientKey("pin_code", "")
    updateErrorMessage("pin_code", "")

    sendClientPin()
      .then((response) => response.data)
      .then((data) => {
        const { waiting_seconds } = data
        updateClientKey("pin_code_waiting_seconds", waiting_seconds)
        handleOpen()
      })
      .catch((error) => {
        const messages = error?.response?.data?.error_messages

        if (messages) {
          updateErrorMessage("mobile_phone", (messages.mobile_phone && messages.mobile_phone[0]) || "")
        }
      })
      .finally(() => {
        updateAppKey("backdrop", false)
      })
  }

  const checkPin = () => {
    updateAppKey("backdrop", true)

    login()
      .then((response) => response.data)
      .then((data) => {
        const { access_token, info } = data

        if (access_token.length > 0) {
          localStorage.setItem("access_token", access_token)

          if (info?.type === "web") {
            updateAppKey("uuid", info?.uuid)
            updateAppKey("current_step", "general_info")
            updateField("mobile_phone", mobilePhone)

            navigate("/autoloan/application/full")
          } else if (info?.type === "web_short") {
            updateAppKey("uuid", info?.uuid)
            updateAppKey("current_step", "general_info_short")
            updateField("mobile_phone", mobilePhone)

            navigate("/autoloan/application/short")
          }

          clearLogin()
        }
      })
      .catch((error) => {
        const messages = error?.response?.data?.error_messages

        if (messages) {
          updateErrorMessage("pin_code", (messages.pin_code && messages.pin_code[0]) || "")
        }
      })
      .finally(() => {
        updateAppKey("backdrop", false)
      })
  }

  const SendOtpBlock = () => (
    <Link sx={{ cursor: "pointer", textDecoration: "none" }} onClick={sendOtp}>
      <Typography variant="body2" sx={{ my: 2 }}>
        Send again
      </Typography>
    </Link>
  )

  const WaitingOtpBlock = () => (
    <Typography variant="body2" sx={{ my: 2 }}>
      Send SMS again after {seconds} sec...
    </Typography>
  )

  return (
    <>
      <Box
        sx={{ my: 4, maxWidth: { xs: "auto", md: 500 }, display: "flex", flexDirection: { xs: "column", md: "row" } }}
      >
        <ClientPhone />
        <SubmitButton text="Apply" onClick={sendOtp} sx={{ maxHeight: 50, mt: "6px", ml: { xs: 0, md: 2 } }} />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 400,
            backgroundColor: "background.paper",
            outline: "none",
            boxShadow: 24,
            p: { xs: 2, md: 3 },
            borderRadius: "10px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{
                width: 24,
                height: 24,
                cursor: "pointer",
                color: "text.secondary",
              }}
              onClick={handleClose}
            />
          </Box>

          <Box id="modal-modal-description" component="form" onSubmit={handleSubmit}>
            <Typography variant="h5" component="h5" sx={{ color: "text.primary", fontWeight: 500, mb: 2 }}>
              Confirmation
            </Typography>
            <Typography variant="body2" component="p" sx={{ color: "text.primary" }}>
              A confirmation code have been sent to your phone number: {mobilePhone}
            </Typography>

            {seconds > 0 ? <WaitingOtpBlock /> : <SendOtpBlock />}
            <PinCode />

            <SubmitButton text="Confirm" type="submit" fullWidth={true} />
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default LoginForm
