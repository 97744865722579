import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"
import InputMask from "react-input-mask"

const CarDownPayment = () => {
  const id = "car_down_payment"

  const validate = (value) => {
    if (value === "") return "This field is required"

    if (value > 999999999) return "Down payment is to large"
    if (value < 10000) return "Down payment is to short"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  return (
    <MyTextField
      id={id}
      _label="Down payment"
      placeholder="Enter down payment"
      type="number"
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    />
  )
}

export default CarDownPayment
