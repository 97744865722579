import { Box, Grid } from "@mui/material"

import useAppRequest from "hooks/use_app_request"

import StepBlock from "components/shared/StepBlock"
import Tin from "components/shared/fields/Tin"
import DriverLicense from "components/shared/fields/DriverLicense"
import DocumentType from "components/shared/fields/DocumentType"
import DocumentNumber from "components/shared/fields/DocumentNumber"
import DocumentFront from "components/shared/fields/DocumentFront"
import DocumentSelfie from "components/shared/fields/DocumentSelfie"
import SecondaryButton from "components/shared/buttons/Secondary"

const GovernmentIssueId = () => {
  const stepName = "government_issued_id"

  const { handleSubmit } = useAppRequest(stepName, {
    tin_document_number: "tin_document_number",
    dl_document_number: "dl_document_number",
    document_type: "personal_document_type_id",
    document_number: "document_number",
    front_document: "front_document",
    selfie_document: "selfie_document",
  })

  return (
    <StepBlock stepName={stepName} stepTitle="Government Issued ID">
      <Box component="form" onSubmit={handleSubmit}>
        <Box sx={{ maxWidth: "388px" }}>
          <Tin />
          <DriverLicense />
          <DocumentType />
          <DocumentNumber />

          <Grid container columnSpacing={2} sx={{ mt: 0, mb: 2.5 }}>
            <Grid item xs={6}>
              <DocumentFront />
            </Grid>
            <Grid item xs={6}>
              <DocumentSelfie />
            </Grid>
          </Grid>

          <SecondaryButton text="Next" type="submit" />
        </Box>
      </Box>
    </StepBlock>
  )
}

export default GovernmentIssueId
