import ApplicationLayout from "../../application_short/Layout"
import { Box, Container, Typography } from "@mui/material"
import Image from "static/success_page_short.png"
import { useEffect } from "react"
import useAppStore from "store"

const Success = () => {
  const resetState = useAppStore((store) => store.resetState)

  useEffect(() => {
    window.scrollTo(0, 0)
    resetState()

    if (typeof window.dataLayer !== "undefined" && window.dataLayer !== null) {
      window.dataLayer.push({ event: "application_completed" })
    }

    if (typeof window.fbq !== "undefined" && window.fbq !== null) {
      window.fbq("track", "Lead")
      window.fbq("track", "SubmitApplication")
    }
  }, [])

  return (
    <ApplicationLayout>
      <Container maxWidth="lg">
        <Box
          sx={{
            height: { xs: 600, md: 800 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: { xs: 135, md: 155 },
              width: { xs: 227, md: 262 },
            }}
            alt="CarDocuments"
            src={Image}
          />
          <Typography variant="h3" sx={{ maxWidth: { xs: 350, md: 550 }, mt: 7, textAlign: "center" }}>
            Thank you for your application!
          </Typography>

          <Typography variant="h6" sx={{ maxWidth: { xs: 350, md: 550 }, mt: 2, textAlign: "center" }}>
            Our specialist will contact you within a day to clarify the details of your application.
          </Typography>
        </Box>
      </Container>
    </ApplicationLayout>
  )
}

export default Success
