import useAppStore from "store"

const useCheckbox = (fieldName, validate) => {
  const inputValue = useAppStore((state) => state.fields[fieldName])
  const updateField = useAppStore((state) => state.updateField)
  const errorMessage = useAppStore((state) => state.error_messages[fieldName])
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)

  const handleChange = (e) => {
    const value = e.target.checked

    const errorMessage = validate(value)

    updateField(fieldName, value)
    updateErrorMessage(fieldName, errorMessage)
  }

  return {
    handleChange,
    inputValue,
    errorMessage,
  }
}

export default useCheckbox
