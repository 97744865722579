import { useEffect, useState } from "react"
import ReactFileReader from "react-file-reader"
import { Box, Typography, FormHelperText } from "@mui/material"

import UploadButton from "components/shared/buttons/Upload"
import ImagePreview from "../ImagePreview"
import useAppStore from "store"
import ImageLoading from "../ImageLoading"
import resizeImage from "config/resizer"

const LivingBill = () => {
  const id = "utility_bill"
  const [url, setUrl] = useState("")
  const [loading, setLoading] = useState(true)

  const errorMessages = useAppStore((state) => state.error_messages)
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)

  const makeStepFailed = useAppStore((state) => state.makeStepFailed)

  const getDocumentPhoto = useAppStore((state) => state.getDocumentPhoto)
  const createDocumentPhoto = useAppStore((state) => state.createDocumentPhoto)
  const removeDocumentPhoto = useAppStore((state) => state.removeDocumentPhoto)

  useEffect(() => {
    fetchBillPhoto()
  }, [])

  const fetchBillPhoto = () => {
    getDocumentPhoto(id)
      .then((response) => response.data)
      .then((data) => {
        setUrl(data.url || "")
        setLoading(false)
        updateErrorMessage(id, "")
      })
  }

  const handleFiles = (files) => {
    setLoading(true)

    const file = files[0]

    resizeImage(file)
      .then((resizedFile) => createDocumentPhoto(id, resizedFile))
      .then(() => fetchBillPhoto())
  }

  const resetImg = () => {
    removeDocumentPhoto(id).then(() => {
      setUrl("")
      makeStepFailed("residence")
    })
  }

  const Upload = () => (
    <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} handleFiles={handleFiles}>
      <UploadButton text="Attach an electricity (or water) bill" />
    </ReactFileReader>
  )

  return (
    <Box>
      <Box sx={{ mb: { xs: 1, md: 2 }, maxWidth: "50%" }}>
        <Typography variant="body2" sx={{ mb: 1, color: "text.middle" }}>
          Bill info
        </Typography>

        {loading ? <ImageLoading /> : url.length > 0 ? <ImagePreview url={url} resetImg={resetImg} /> : <Upload />}

        <FormHelperText error={errorMessages.utility_bill !== ""}>{errorMessages.utility_bill}</FormHelperText>
      </Box>
      <Typography variant="caption" component="p" sx={{ color: "text.secondary", mb: 2.5 }}>
        Latest Electric or Water Bill. If not in the name of the borrower, you must submit additional proof of residence
        - any documents under your name and with your current address.
      </Typography>
    </Box>
  )
}

export default LivingBill
