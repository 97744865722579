import { Box, Grid } from "@mui/material"

import useAppRequest from "hooks/use_app_request"
import useAppStore from "store"

import StepBlock from "components/shared/StepBlock"
import SocialStatus from "components/shared/fields/SocialStatus"

import CompanyPhoneNumber from "components/shared/fields/CompanyPhoneNumber"
import CompanyName from "components/shared/fields/CompanyName"
import CompanyAddress from "components/shared/fields/CompanyAddress"
import CompanyPosition from "components/shared/fields/CompanyPosition"
import MonthlyIncome from "components/shared/fields/MonthlyIncome"
import CompanyLengthService from "components/shared/fields/CompanyLengthService"
import WorkNature from "components/shared/fields/WorkNature"
import Payslip from "components/shared/fields/Payslip"
import RegularDocument from "components/shared/fields/RegularDocument"
import SecondaryButton from "components/shared/buttons/Secondary"

const FinancialInfo = () => {
  const stepName = "financial_info"

  const { handleSubmit } = useAppRequest(stepName, {
    social_status_id: "social_status_id",
    company_name: "company_name",
    company_phone: "company_phone",
    company_address: "company_address",
    company_position: "company_position",
    length_service: "length_service",
    work_nature: "work_nature",
    monthly_income: "salary",
    payslip: "payslip",
    regular_document: "regular_document",
  })

  const social_status_id = useAppStore((state) => state.fields?.social_status_id)

  const stepSubmit = (event) => {
    handleSubmit(event)
  }

  // +--+------------------------+
  // |id|name                    |
  // +--+------------------------+
  // |10|Business Owner          |
  // |9 |Freelancer/Self Employed|
  // |13|Unemployed              |
  // |15|Employed                |
  // +--+------------------------+

  return (
    <StepBlock stepName={stepName} stepTitle="Financial info">
      <Box component="form" onSubmit={stepSubmit}>
        <Box sx={{ maxWidth: "388px" }}>
          <SocialStatus />

          {[10, 15].includes(social_status_id) && <CompanyName />}
          {[10, 15].includes(social_status_id) && <CompanyPhoneNumber />}
          {[10, 15].includes(social_status_id) && <CompanyAddress />}
          {social_status_id === 15 && <CompanyPosition />}
          {social_status_id === 15 && <CompanyLengthService />}
          {social_status_id === 9 && <WorkNature />}

          <MonthlyIncome />

          <Grid container columnSpacing={2} sx={{ mt: 0, mb: 2.5 }}>
            <Grid item xs={6}>
              {!!social_status_id && social_status_id !== 13 && <Payslip />}
            </Grid>
            <Grid item xs={6}>
              {social_status_id === 10 && <RegularDocument />}
            </Grid>
          </Grid>

          <SecondaryButton text="Next" type="submit" />
        </Box>
      </Box>
    </StepBlock>
  )
}

export default FinancialInfo
