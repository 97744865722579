import { useEffect, useState } from "react"

import useAppStore from "store"

import { Autocomplete, TextField, Box, Typography, FormControl, FormHelperText } from "@mui/material"

const LivingCity = () => {
  const field_id = "living_city_id"

  const inputValue = useAppStore((state) => state.fields[field_id])
  const setInputValue = useAppStore((state) => state.updateField)
  const errorMessage = useAppStore((state) => state.error_messages[field_id])
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)

  const [open, setOpen] = useState(false)
  const [cities, setCities] = useState([])
  const [loading, setLoading] = useState(false)

  const getCities = useAppStore((state) => state.getCities)

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const handleChange = (_e, obj) => {
    // const { value } = obj

    setInputValue(field_id, obj)
  }

  const handleClose = (_e, obj) => {
    const { value } = obj

    const errorMessage = validate(value)

    updateErrorMessage(field_id, errorMessage)

    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  useEffect(() => {
    fetchCities()
  }, [])

  const fetchCities = () => {
    setLoading(true)

    getCities()
      .then((response) => response.data)
      .then((data) => {
        const cities = data.map((opt) => ({ label: opt.name, value: opt.id }))
        setCities(cities)
        setLoading(false)
      })
  }

  return (
    <Box id={field_id}>
      <Typography variant="body2" color="text.middle" sx={{ mb: 1 }}>
        Province/City/Municipality
      </Typography>

      <FormControl fullWidth sx={{ mb: { xs: 2, md: 2.5 } }} error={errorMessage?.length > 0}>
        <Autocomplete
          disablePortal
          options={cities}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          value={inputValue}
          onChange={handleChange}
          noOptionsText="Please type at least 3 letters"
          isOptionEqualToValue={(option, obj) => String(option.value) === String(obj.value)}
          getOptionLabel={(option) => String(option.label)}
          loading={loading}
          filterOptions={(options, state) => {
            if (state.inputValue.length >= 3) {
              return options.filter((item) => String(item.label).toLowerCase().includes(state.inputValue.toLowerCase()))
            } else {
              return []
            }
          }}
          renderInput={(params) => (
            <TextField
              error={errorMessage?.length > 0}
              {...params}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "4px",
                  backgroundColor: "#F8F8FA",
                  height: 50,
                  fontWeight: 400,
                },
              }}
            />
          )}
        />

        <FormHelperText>
          {errorMessage ||
            "If you can not find your city / municipality choose another closest city / municipality to you"}
        </FormHelperText>
      </FormControl>
    </Box>
  )
}

export default LivingCity
