import { Box } from "@mui/material"

import useAppRequest from "hooks/use_app_request"

import StepBlock from "components/shared/StepBlock"
import CarModel from "components/shared/fields/CarModel"
import CarYear from "components/shared/fields/CarYear"
import CarMileage from "components/shared/fields/CarMileage"
import SecondaryButton from "components/shared/buttons/Secondary"

const CarGeneralInfo = () => {
  const stepName = "car_general_info"

  const { handleSubmit } = useAppRequest(stepName, {
    car_model: "name",
    car_year: "year",
    car_plate_number: "plate_number",
    car_mileage: "mileage",
  })

  return (
    <StepBlock stepName={stepName} stepTitle="Unit details">
      <Box sx={{ maxWidth: "388px" }} component="form" onSubmit={handleSubmit}>
        <CarModel />
        <CarYear />
        <CarMileage />
        <SecondaryButton text="Next" type="submit" />
      </Box>
    </StepBlock>
  )
}

export default CarGeneralInfo
