import { createRef, useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"

import ApplicationLayout from "components/views/application_full/Layout"
import StepsLayout from "components/views/application_full/steps/Layout"

import GeneralInfo from "./GeneralInfo"
import Residence from "./Residence"
import GovernmentIssueId from "./GovernmentIssueId"
import References from "./References"
import FinancialInfo from "./FinancialInfo"
import CarGeneralInfo from "./CarGeneralInfo"

import SubmitButton from "components/shared/buttons/Submit"

import useAppStore from "store"
import LoanParameters from "./LoanParameters"

const Steps = () => {
  const steps = useAppStore((state) => state.steps)
  const [disabled, setDisabled] = useState(true)
  const formRef = createRef()

  useEffect(() => {
    window.scrollTo(0, 0)

    const valid =
      steps["general_info"] &&
      steps["residence"] &&
      steps["government_issued_id"] &&
      steps["references"] &&
      steps["car_general_info"]

    setDisabled(!valid)
  }, [steps])

  const handleSubmit = () => {
    formRef.current.requestSubmit()
  }

  return (
    <ApplicationLayout>
      <StepsLayout>
        <Typography variant="h4" component="h4" sx={{ mb: { xs: 2, md: 2.5 } }}>
          Application form
        </Typography>

        <Box sx={{ mx: { xs: -2, md: 0 } }}>
          <GeneralInfo />
          <Residence />
          <GovernmentIssueId />
          <References />
          <FinancialInfo />
          <CarGeneralInfo />
          <LoanParameters ref={formRef} />
        </Box>

        <Box sx={{ mt: 3.5, display: "flex", justifyContent: "flex-end" }}>
          <SubmitButton text="Finish application" disabled={disabled} onClick={handleSubmit} />
        </Box>
      </StepsLayout>
    </ApplicationLayout>
  )
}

export default Steps
