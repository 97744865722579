import { useEffect } from "react"

import useAppStore from "store"
import useClientStore from "store/client"

import NavbarShort from "components/shared/NavbarShort"
import Footer from "components/shared/Footer"

import Entry from "./Entry"
import Eligibility from "./Eligibility"
import Reviews from "./Reviews"
import AppProcess from "./AppProcess"
import RequiredDocs from "./RequiredDocs"
import Faq from "./Faq"
import VideoReviews from "./VideoReviews"
import Regions from "./Regions"

const Home = () => {
  const resetState = useAppStore((store) => store.resetState)
  const updateUtmParam = useClientStore((store) => store.updateUtmParam)
  const resetClient = useClientStore((store) => store.resetClient)
  // const settings = useClientStore((store) => store.settings)
  // const getSettings = useClientStore((store) => store.getSettings)
  // const updateSettings = useClientStore((store) => store.updateSettings)

  const syncQueryParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    updateUtmParam("utm_source", params.utm_source)
    updateUtmParam("utm_medium", params.utm_medium)
    updateUtmParam("utm_campaign", params.utm_campaign)
    updateUtmParam("utm_term", params.utm_term)
    updateUtmParam("utm_content", params.utm_content)
    updateUtmParam("click_id", params.click_id || params.clickid || params.clickId)
  }

  // const syncSettings = () => {
  //   getSettings()
  //     .then((response) => response.data)
  //     .then((data) => {
  //       if ("full_app" in settings) return
  //
  //       updateSettings("full_app", data?.full_app)
  //     })
  // }

  useEffect(() => {
    syncQueryParams()
    // syncSettings()
    resetState()
    resetClient()
  }, [])

  return (
    <>
      <NavbarShort white="true" />
      <Entry />
      <AppProcess />
      <Reviews />
      <Eligibility />
      <VideoReviews />
      <Regions />
      <RequiredDocs />
      <Faq />
      <Footer />
    </>
  )
}

export default Home
