import { FormControl, FormLabel, FormControlLabel, RadioGroup, FormHelperText, Typography, Radio } from "@mui/material"
import useInput from "hooks/use_input"

import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined"
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler"
import AssuredWorkloadOutlined from "@mui/icons-material/AssuredWorkloadOutlined"
import { useEffect, useState } from "react"
import useAppStore from "store"

// import { ReactComponent as Car } from "static/icons/radio/car.svg"
// import { ReactComponent as Moto } from "static/icons/radio/moto.svg"
// import { ReactComponent as Sangla } from "static/icons/radio/sangla.svg"
//
// const CarIcon = () => <SvgIcon component={Car} />
// const MotoIcon = () => <SvgIcon component={Moto} />
// const SanglaIcon = () => <SvgIcon component={Sangla} />

const RadioCard = (props) => {
  const { label, value, selected, icon } = props

  const RadioIcon = (props) => <Radio sx={{}} {...props} />

  return (
    <FormControlLabel
      value={value}
      label={label}
      control={
        <RadioIcon
          icon={icon}
          checkedIcon={icon}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 32,
            },
          }}
        />
      }
      sx={{
        flex: 1,
        p: 3,
        mb: 1,
        ml: "2px",
        minWidth: { xs: "90vw", md: "auto" },
        border: "1px solid",
        borderRadius: "4px",
        // color: selected ? "text.primary" : "#AAAAAA",
        backgroundColor: selected ? "primary.outlined" : "#F8F8FA",
        borderColor: selected ? "primary.main" : "#DBDBE5",
      }}
    />
  )
}

const ProductType = () => {
  const id = "application_type_id"

  const [productTypes, setProductTypes] = useState([{ id: 999, name: "None" }])
  // const [loaded, setLoaded] = useState(false)

  const getProductTypes = useAppStore((state) => state.getProductTypes)

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  useEffect(() => {
    fetchProductTypes()
  }, [])

  const fetchProductTypes = () => {
    getProductTypes()
      .then((response) => response.data)
      .then((data) => {
        setProductTypes(data)
        // setLoaded(true)
      })
  }

  const getIcon = (name) => {
    if (name === "Car Financing") return <DirectionsCarFilledOutlinedIcon />
    if (name === "Moto Financing") return <TwoWheelerIcon />
    if (name === "Sangla ORCR") return <AssuredWorkloadOutlined />
  }

  return (
    <FormControl error={errorMessage?.length > 0}>
      <FormLabel>
        <Typography variant="body2" color="text.middle" sx={{ mb: 1 }}>
          Product type
        </Typography>
      </FormLabel>

      <RadioGroup
        id={id}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between", mr: -1.5 }}
      >
        {productTypes.map((app_type) => (
          <RadioCard
            key={app_type.id}
            value={app_type.id}
            label={app_type.name}
            selected={inputValue === app_type.id.toString()}
            icon={getIcon(app_type.name)}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

export default ProductType
