import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"

const SpouseName = () => {
  const id = `spouse_full_name`

  const validate = (value) => {
    if (value === "") return "This field is required"
    if (value.length > 90) return "Full name is to long"

    const words = value.match(/[^ ]+/g)

    if (words.length < 3) return "Full name should contain 3 words minimum"
    if (words.length > 5) return "Full name should contain 5 words maximum"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  const customHandleChange = (event) => {
    // only words and space
    const modifiedEvent = {
      ...event,
      target: { ...event.target, value: event.target.value.replace(/[^a-z ]/gi, "") },
    }

    handleChange(modifiedEvent)
  }

  return (
    <MyTextField
      id={id}
      _label="Spouse Name"
      placeholder="Full name"
      value={inputValue}
      onChange={customHandleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    />
  )
}

export default SpouseName
