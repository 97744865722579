import { useEffect, useState } from "react"
import ReactFileReader from "react-file-reader"
import { Typography, FormHelperText, Box } from "@mui/material"

import UploadButton from "components/shared/buttons/Upload"
import ImagePreview from "../ImagePreview"
import ImageLoading from "../ImageLoading"

import resizeImage from "config/resizer"
import useAppStore from "store"

const RegularDocument = () => {
  const id = "regular_document"
  const [url, setUrl] = useState("")
  const [loading, setLoading] = useState(true)

  const errorMessages = useAppStore((state) => state.error_messages)
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)
  const makeStepFailed = useAppStore((state) => state.makeStepFailed)

  const getDocumentPhoto = useAppStore((state) => state.getDocumentPhoto)
  const createDocumentPhoto = useAppStore((state) => state.createDocumentPhoto)
  const removeDocumentPhoto = useAppStore((state) => state.removeDocumentPhoto)

  useEffect(() => {
    fetchRegularDocument()
  }, [])

  const fetchRegularDocument = () => {
    getDocumentPhoto(id)
      .then((response) => response.data)
      .then((data) => {
        setUrl(data.url || "")
        setLoading(false)
        updateErrorMessage(id, "")
      })
  }

  const handleFiles = (files) => {
    setLoading(true)

    const file = files[0]

    resizeImage(file)
      .then((resizedFile) => createDocumentPhoto(id, resizedFile))
      .then(() => fetchRegularDocument())
  }

  const resetImg = () => {
    removeDocumentPhoto(id).then(() => {
      setUrl("")
      makeStepFailed("financial_info")
    })
  }

  const Upload = () => (
    <ReactFileReader fileTypes={[".png", ".jpg", ".jpeg"]} handleFiles={handleFiles}>
      <UploadButton text="Attach a document" />
    </ReactFileReader>
  )

  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 1, color: "text.middle" }}>
        DTL, SEC, GIS
      </Typography>

      {loading ? <ImageLoading /> : url?.length > 0 ? <ImagePreview url={url} resetImg={resetImg} /> : <Upload />}

      <FormHelperText error={errorMessages.regular_document !== ""}>{errorMessages.regular_document}</FormHelperText>
    </Box>
  )
}

export default RegularDocument
