import { FormControl, Box, Typography } from "@mui/material"
import BaseCheckbox from "components/base/Checkbox"
import useCheckbox from "hooks/use_checkbox"
import { useEffect } from "react"
import useAppStore from "../../../store"

const ParentSkip = () => {
  const id = "parent_skip"

  const { updateErrorMessage } = useAppStore()

  const validate = () => {
    return ""
  }

  const { handleChange, inputValue } = useCheckbox(id, validate)

  useEffect(() => {
    if (inputValue) {
      updateErrorMessage("parent_full_name", "")
      updateErrorMessage("parent_phone_number", "")
      updateErrorMessage("parent_address", "")
    }
  }, [inputValue])

  return (
    <FormControl>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <BaseCheckbox checked={inputValue} onChange={handleChange} />
        <Typography variant="body2">Not applicable</Typography>
      </Box>
    </FormControl>
  )
}

export default ParentSkip
