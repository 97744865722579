import { Checkbox, SvgIcon } from "@mui/material"
import { ReactComponent as Base } from "static/icons/checkbox/base.svg"
import { ReactComponent as Checked } from "static/icons/checkbox/checked.svg"

const CheckboxIcon = () => <SvgIcon component={Base} />
const CheckboxIconChecked = () => <SvgIcon component={Checked} />

const BaseCheckbox = (props) => (
  <Checkbox
    icon={<CheckboxIcon />}
    checkedIcon={<CheckboxIconChecked />}
    sx={{
      "& .MuiSvgIcon-root": { fontSize: 24 },
      mr: 2,
      p: 0,
    }}
    {...props}
  />
)
export default BaseCheckbox
