import { BrowserRouter, Route, Routes } from "react-router-dom"

import theme from "config/theme"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"

import HomePage from "components/views/home"
import Backdrop from "components/shared/Backdrop"
import PageNotFound from "components/views/404"
import TechnicalError from "components/views/500"

import StepsShort from "components/views/application_short/steps"
import StepsFull from "components/views/application_full/steps"

import NewApp from "components/views/application_partner/NewApp"
import StepsPartner from "components/views/application_partner/steps"

import Success from "components/views/result/Success"
import Reject from "components/views/result/Reject"

// import ClientInfo from "components/views/client"

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Backdrop />
    <BrowserRouter>
      <Routes>
        <Route path="/autoloan">
          <Route path="" element={<HomePage />} />
          <Route path="partner" element={<NewApp />} />

          {/* Application routes */}
          <Route path="application">
            <Route path="short" element={<StepsShort />} />
            <Route path="full" element={<StepsFull />} />
            <Route path="partner" element={<StepsPartner />} />

            <Route path="success" element={<Success />} />
            <Route path="reject" element={<Reject />} />
          </Route>

          {/* Client routes*/}
          {/*<Route path="me">*/}
          {/*  <Route path="" element={<ClientInfo />} />*/}
          {/*  <Route path="partner_app" element={<NewApp />} />*/}
          {/*</Route>*/}

          <Route path="technical_error" element={<TechnicalError />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
)

export default App
