import MyTextField from "components/base/TextField"
import InputMask from "react-input-mask"
import useClientStore from "../../../store/client"
import { useState } from "react"
import ENABLE_VALIDATIONS from "../../../config/validations"

const PinCode = () => {
  const inputValue = useClientStore((state) => state.pin_code)
  const updateKey = useClientStore((state) => state.updateKey)
  const errorMessage = useClientStore((state) => state.error_messages.pin_code)
  const updateErrorMessage = useClientStore((state) => state.updateErrorMessage)
  const [touched, setTouched] = useState(false)

  const id = "pin_code"

  const validate = (value) => {
    if (value === "") return "This field is required"
    if (value.length < 4) return "Is the wrong length (should be 4 characters)"

    return ""
  }

  const handleChange = (e) => {
    const { value } = e.target

    updateKey(id, value)

    if (!touched) return

    const errorMessage = ENABLE_VALIDATIONS ? validate(value) : ""

    updateErrorMessage(id, errorMessage)
  }

  const handleBlur = (e) => {
    if (touched) return

    setTouched(true)

    const { value } = e.target

    const errorMessage = ENABLE_VALIDATIONS ? validate(value) : ""

    updateErrorMessage(id, errorMessage)
  }

  const mask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]

  return (
    <InputMask
      mask={mask}
      maskPlaceholder=""
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    >
      <MyTextField id={id} _label="Code from message" placeholder="0000" />
    </InputMask>
  )
}

export default PinCode
