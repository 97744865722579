import { Box, Stack, useMediaQuery, Typography } from "@mui/material"

import useAppRequest from "hooks/use_app_request"

import StepBlock from "components/shared/StepBlock"
import CarModel from "components/shared/fields/CarModel"
import CarYear from "components/shared/fields/CarYear"
import CarPlateNumber from "components/shared/fields/CarPlateNumber"
import CarMileage from "components/shared/fields/CarMileage"
import SecondaryButton from "components/shared/buttons/Secondary"

const CarGeneralInfo = () => {
  const stepName = "car_general_info"

  const { handleSubmit } = useAppRequest(stepName, {
    car_model: "name",
    car_year: "year",
    car_plate_number: "plate_number",
    car_mileage: "mileage",
  })

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <StepBlock stepName={stepName} stepTitle="Unit details">
      <Box sx={{ maxWidth: "388px" }} component="form" onSubmit={handleSubmit}>
        <Typography variant="body1" sx={{ mb: { xs: 2, md: 2.5 } }}>
          Must be under the borrower’s name, unencumbered and owned for at least 6 months
        </Typography>
        <CarModel />
        <Stack direction={matches ? "row" : "column"} spacing={matches ? 3 : 0}>
          <CarYear />
          <CarPlateNumber />
        </Stack>

        <Box sx={{ maxWidth: matches ? "183px" : "100%", mb: 1 }}>
          <CarMileage />
        </Box>
        <SecondaryButton text="Next" type="submit" />
      </Box>
    </StepBlock>
  )
}

export default CarGeneralInfo
