import { Box, Typography, Alert } from "@mui/material"

import useAppRequest from "hooks/use_app_request"

import StepBlock from "components/shared/StepBlock"

import ReferenceStatus from "components/shared/fields/ReferenceStatus"
import ReferenceFullName from "components/shared/fields/ReferenceFullName"
import ReferencePhoneNumber from "components/shared/fields/ReferencePhoneNumber"
import SecondaryButton from "components/shared/buttons/Secondary"
import useAppStore from "../../../../store"
import SpousePhoneNumber from "../../../shared/fields/SpousePhoneNumber"
import SpouseName from "../../../shared/fields/SpouseName"
import ParentFullName from "../../../shared/fields/ParentFullName"
import ParentPhoneNumber from "../../../shared/fields/ParentPhoneNumber"
import ParentAddress from "../../../shared/fields/ParentAddress"
import ParentSkip from "../../../shared/fields/ParentSkip"

const References = () => {
  const stepName = "references"
  const { civil_status_id, parent_skip } = useAppStore((state) => state.fields)

  const { handleSubmitReferences } = useAppRequest(stepName, {
    spouse_full_name: "spouse_full_name",
    spouse_phone_number: "spouse_phone_number",
    parent_full_name: "parent_full_name",
    parent_phone_number: "parent_phone_number",
    parent_address: "parent_address",
    parent_skip: "parent_skip",
    reference1_id: "reference1_id",
    reference1_full_name: "reference1_full_name",
    reference1_phone_number: "reference1_phone_number",
    reference2_id: "reference2_id",
    reference2_full_name: "reference2_full_name",
    reference2_phone_number: "reference2_phone_number",
  })

  return (
    <StepBlock stepName={stepName} stepTitle="References">
      <Box component="form" onSubmit={handleSubmitReferences}>
        <Box sx={{ maxWidth: "388px" }}>
          {[2, 6].includes(civil_status_id) && (
            <Box>
              <Typography variant="h6" component="h6" sx={{ fontWeight: 700, my: 2 }}>
                Spouse
              </Typography>
              <SpouseName />
              <SpousePhoneNumber />
            </Box>
          )}

          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="h6" component="h6" sx={{ fontWeight: 700, my: 2 }}>
                Parent
              </Typography>
              <ParentSkip />
            </Box>

            <Alert
              severity="info"
              sx={{
                borderRadius: 1,
                mb: parent_skip ? 4 : 2,
              }}
            >
              Please provide any of your parents’ or guardian’s details. If a parent is deceased, divorced, or otherwise
              not applicable, select “Not applicable.”
            </Alert>

            {!parent_skip && (
              <>
                <ParentFullName />
                <ParentPhoneNumber />
                <ParentAddress />
              </>
            )}
          </Box>

          <Box>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 700, my: 2 }}>
              Reference 1
            </Typography>
            <ReferenceStatus number="1" />
            <ReferenceFullName number="1" />
            <ReferencePhoneNumber number="1" />
          </Box>

          <Box>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 700, my: 2 }}>
              Reference 2
            </Typography>
            <ReferenceStatus number="2" />
            <ReferenceFullName number="2" />
            <ReferencePhoneNumber number="2" />
          </Box>
        </Box>
        <SecondaryButton text="Next" type="submit" />
      </Box>
    </StepBlock>
  )
}

export default References
