import { createTheme } from "@mui/material/styles"

let theme = createTheme({})

const customTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00B71D",
      outlined: "#EBFFEE",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FF9900",
      outlined: "#FFEFD7",
      contrastText: "#fff",
    },
    bg: {
      main: "#F7F7F7",
      lightGreen: "#E4FFE9",
    },
    text: {
      primary: "#212D45",
      secondary: "#7C8599",
      middle: "#465063",
      light: "#fff",
    },
  },

  typography: {
    allVariants: {
      fontFamily: ["casper", "inter"].join(","),
      textTransform: "none",
    },
  },

  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: 8,
          lineHeight: 1.1,
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "h1" },
          style: {
            fontSize: 77,
            fontWeight: 700,
            lineHeight: "78px",
            [theme.breakpoints.down("sm")]: {
              fontSize: 32,
              lineHeight: "33px",
            },
          },
        },

        {
          props: { variant: "h2" },
          style: {
            fontSize: 52,
            fontWeight: 400,
            lineHeight: "57px",
            [theme.breakpoints.down("sm")]: {
              fontSize: 34,
              lineHeight: "37px",
            },
          },
        },
        {
          props: { variant: "h3" },
          style: {
            fontSize: 36,
            fontWeight: 400,
            lineHeight: "39.31px",
            [theme.breakpoints.down("sm")]: {
              fontSize: 28,
              lineHeight: "30.58px",
            },
          },
        },
        {
          props: { variant: "h4" },
          style: {
            fontSize: 24,
            fontWeight: 400,
            lineHeight: "26.21px",
            [theme.breakpoints.down("sm")]: {
              fontSize: 20,
              lineHeight: "22px",
            },
          },
        },
        {
          props: { variant: "h5" },
          style: {
            fontSize: 22,
            fontWeight: 500,
            lineHeight: "26px",
            [theme.breakpoints.down("sm")]: {
              fontSize: 20,
              lineHeight: "22px",
            },
          },
        },
        {
          props: { variant: "h6" },
          style: {
            fontSize: 18,
            fontWeight: 400,
            lineHeight: "20px",
            [theme.breakpoints.down("sm")]: {
              fontSize: 16,
              lineHeight: "19px",
            },
          },
        },
        {
          props: { variant: "body1" },
          style: {
            fontSize: "16px",
            lineHeight: "19px",
          },
        },
        {
          props: { variant: "body2" },
          style: {
            fontSize: "14px",
            lineHeight: "15.29px",
          },
        },

        {
          props: { variant: "subtitle2" },
          style: {
            fontSize: "13px",
            lineHeight: "14.29px",
            fontWeight: 400,
          },
        },
        {
          props: { variant: "caption" },
          style: {
            fontSize: "12px",
            lineHeight: "13px",
          },
        },
      ],
    },
  },
})

export default customTheme
