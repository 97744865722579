import { Container, Box, Card, Paper, Typography, Button, ButtonGroup, useMediaQuery } from "@mui/material"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"

const videos = [
  {
    // src: "https://www.youtube.com/embed/a8_0ZdiLRlk",
  },
  {
    // src: "https://www.youtube.com/embed/205mJRC9vt0",
  },
]

// eslint-disable-next-line no-unused-vars
const Buttons = ({ next, previous, goToSlide, carouselState }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        my: { md: 8, xs: 6 },
        mx: { md: 1, xs: 0 },
      }}
    >
      <Typography variant="h3" sx={{ mb: -4 }}>
        Video about OLP
      </Typography>
      {matches && (
        <ButtonGroup>
          <Button onClick={() => previous()} color="inherit" sx={{ color: "#E0E0E7", borderColor: "#E0E0E7", py: 0.5, px: 0 }}>
            <KeyboardArrowLeftIcon />
          </Button>
          <Button onClick={() => next()} color="inherit" sx={{ color: "#E0E0E7", borderColor: "#E0E0E7", py: 0.5, px: 0 }}>
            <KeyboardArrowRightIcon />
          </Button>
        </ButtonGroup>
      )}
    </Box>
  )
}

// eslint-disable-next-line no-unused-vars
const Dots = ({ onMove, index, onClick, active }) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <Box
      component="li"
      sx={{
        width: { xs: 12, md: 16 },
        height: { xs: 12, md: 16 },
        backgroundColor: active ? "secondary.main" : "bg.main",
        borderRadius: "50%",
        mx: 2,
        position: "relative",
        mb: 0,
      }}
      onClick={() => onClick()}
    />
  )
}

const VideoReviews = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"))

  return (
    <Container maxWidth="xl">
      <Paper
        id="reviwes"
        elevation={0}
        sx={{
          pb: { xs: 6, lg: 13 },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column-reverse", mx: { md: -1, xs: 0 } }}>
          <Carousel
            autoPlay={false}
            showDots={!matches}
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<Buttons />}
            customDot={<Dots />}
            responsive={responsive}
          >
            {videos.map((item, i) => (
              <Box sx={{ pb: 4 }} key={i}>
                <Card
                  orientation="horizontal"
                  size="sm"
                  variant="outlined"
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    height: matches ? "90%" : "95%",
                    mx: { md: 1, xs: 0 },
                    my: 0,
                  }}
                >
                  <Box component="iframe" sx={{ height: { xs: 250, md: 400 }, width: "100%" }} src={item.src} frameBorder="0" />
                </Card>
              </Box>
            ))}
          </Carousel>
        </Box>
      </Paper>
    </Container>
  )
}

export default VideoReviews
