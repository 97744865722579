import InputMask from "react-input-mask"
import MyTextField from "components/base/TextField"
import { useState } from "react"
import useClientStore from "store/client"

const ClientPhone = () => {
  const id = "mobile_phone"

  const inputValue = useClientStore((state) => state[id])
  const updateKey = useClientStore((state) => state.updateKey)
  const errorMessage = useClientStore((state) => state.error_messages[id])
  const updateErrorMessage = useClientStore((state) => state.updateErrorMessage)
  const [touched, setTouched] = useState(true)

  const validate = (value) => {
    if (value === "") return "This field is required."

    if (value.replace(/\D/g, "").length < 11) return "Should contain 11 numbers"

    return ""
  }

  const handleChange = (e) => {
    const { value } = e.target

    updateKey(id, value)

    if (!touched) return

    const errorMessage = validate(value)

    updateErrorMessage(id, errorMessage)
  }

  const handleBlur = (e) => {
    if (touched) return

    setTouched(true)

    const { value } = e.target

    const errorMessage = validate(value)

    updateErrorMessage(id, errorMessage)
  }

  const mask = ["0", "9", /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]

  return (
    // https://blog.logrocket.com/implementing-react-input-mask-web-apps/
    <InputMask
      required
      mask={mask}
      maskPlaceholder="_"
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    >
      <MyTextField id={id} _placeholder="Phone number" inputProps={{ inputMode: "numeric" }} />
    </InputMask>
  )
}
export default ClientPhone
