import { create } from "zustand"
import { persist } from "zustand/middleware"
import $api from "config/api"

const initialState = {
  mobile_phone: "",
  pin_code: "",
  pin_code_waiting_seconds: "",
  error_messages: {},
  settings: {},
  applications: [],
  pending_app: {},
  utm_params: {
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
    click_id: "",
  },
}

const useClientStore = create(
  persist(
    // eslint-disable-next-line no-unused-vars
    (set, get) => ({
      ...initialState,

      updateUtmParam: (key, value) => set((state) => ({ utm_params: { ...state.utm_params, [key]: value || "" } })),

      getSettings: () => $api.get("/info/settings"),

      updateSettings: (key, value) => set((state) => ({ settings: { ...state.settings, [key]: value } })),

      sendClientPin: () => $api.post("clients/auth/reset_pin", { mobile_phone: get().mobile_phone }),

      login: () =>
        $api.post("clients/auth/login", {
          mobile_phone: get().mobile_phone,
          pin_code: get().pin_code,
          utm_params: get().utm_params,
        }),

      updateKey: (key, value) => set((state) => ({ ...state }, { [key]: value })),

      updateErrorMessage: (key, value) =>
        set((state) => ({ error_messages: { ...state.error_messages, [key]: value } })),

      // getClientInfo: () => $api.get(`/clients/get_client_info`),

      // setClientInfo: (data) => set((state) => ({ ...state, ...data })),

      // logout: () => $api.post(`/clients/auth/logout`),

      clearLogin: () => set((state) => ({ ...state, pin_code: "", pin_code_waiting_seconds: "", error_messages: {} })),
      resetClient: () => set((state) => ({ ...initialState, utm_params: state.utm_params })),
    }),
    {
      name: "client",
    },
  ),
)

export default useClientStore
