import { Link } from "react-router-dom"
import { Container, AppBar, Drawer, Toolbar, IconButton, useMediaQuery, Box, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"

import Logo from "./Logo"
import Links from "./Links"
import DrawerLinks from "./DrawerLinks"

import SubmitButton from "components/shared/buttons/Submit"
import { useState } from "react"
// import LogoInfo from "./LogoInfo"
import CallPhone from "./CallPhone"
import WorkingHours from "./WorkingHours"

const DesktopNavbar = (props) => {
  const { onlyLogo } = props

  return (
    <AppBar
      id="navbar"
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: "#fff",
        color: "#212D45",
        borderBottom: onlyLogo && "1px solid #F0F0F5",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ height: 90 }} disableGutters>
          <Link to="/autoloan">
            <Logo sx={{ width: 208, height: 86 }} />
          </Link>

          {!onlyLogo && (
            <>
              <Links />
              <CallPhone />
              <WorkingHours />
              <SubmitButton text="Take money" href="#entry" />
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

const MobileNavbar = (props) => {
  const [open, setOpen] = useState(false)
  const { onlyLogo } = props

  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <AppBar
      id="navbar"
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: "#fff",
        color: "#212D45",
        borderBottom: onlyLogo && "1px solid #F0F0F5",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ height: 58, display: "flex", justifyContent: "space-between" }}>
          <Link to="/autoloan">
            <Logo mobile="true" sx={{ width: 183, height: 86, ml: -2, mt: 4 }} white="true" />
          </Link>

          {!onlyLogo && (
            <Box sx={{ display: "flex", alignItems: "center" }} onClick={toggleDrawer}>
              <Typography variant="h6" component="h6">
                Menu
              </Typography>
              <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ ml: 0.5 }}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="right" open={open}>
                <DrawerLinks toggleDrawer={toggleDrawer} />
              </Drawer>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

const Navbar = (props) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("lg"))

  if (matches) return <DesktopNavbar {...props} />

  return <MobileNavbar {...props} />
}

export default Navbar
