import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"

const CarModel = () => {
  const id = "car_model"

  const validate = (value) => {
    if (value === "") return "This field is required"

    if (value.length < 2) return "Is too short (minimum is 2 characters)"
    if (value.length > 15) return "Is too long (maximum is 15 characters)"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  return (
    <MyTextField
      id={id}
      _label="Maker & Model"
      placeholder="Car maker & model"
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    />
  )
}

export default CarModel
